<template>
  <page-main
    title="This is your Education overview"
    :aside="[
      'If you have completed a college degree, you don\'t need to list a high school.',
    ]"
    nextText="Done with school? Let's talk about work."
    :buttonTabIndex="addEduTab + 1 + ''"
    eventName="nav"
    @nav="nav('/Work')"
    @save="save()"
  >
    <v-container fluid class="container-main">
      <v-row>
        <v-col
          cols="12"
          class="r-col"
          v-for="(item, i) in fullList"
          :key="item.key"
        >
          <list-card
            :title="item.general"
            @orderup="orderUp(item.key)"
            @orderdown="orderDown(item.key)"
            :disableUp="i === 0"
            :disableDown="i === fullList.length - 1"
          >
            <div class="d-flex justify-space-between align-center">
              <div>
                <div v-if="HasValue(item, 'degree')">
                  {{ item.degree }} {{ item.degreeMinor }}
                </div>
                <div v-if="hasDateOrLocation(item)">
                  {{ buildDateLocatonString(item.date, item.location) }}
                </div>
                <div v-if="HasValue(item, 'expectedGrad')">{{ item.expectedGrad }}</div>
                <div v-if="HasValue(item, 'gpa')">{{ item.gpa }}</div>
                <div v-if="HasValue(item, 'accolades')" class="pl-2">
                  <span
                    v-for="acc in item.accolades"
                    :key="acc"
                    class="text-body-2"
                  >
                    {{ acc }}
                    <br />
                  </span>
                </div>
              </div>
              <div class="d-flex flex-column align-start">
                <v-btn
                  tile
                  text
                  @click="navWithKey('/educationEntryGeneral/', item.key)"
                  :tabindex="(2 * i) + 1"
                >
                  <v-icon class="pr-2"> mdi-pencil-outline </v-icon>
                  Edit
                </v-btn>
                <v-btn tile text @click.stop="launchDialog(item)" :tabindex="(2 * i) + 2">
                  <v-icon class="pr-2">mdi-delete</v-icon>
                  Remove
                </v-btn>
              </div>
            </div>
          </list-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-4">
        <v-col cols="auto">
          <v-btn outlined @click="navNewRecord()" :tabindex="addEduTab">Add Education Record</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="dialog" title="You are about to remove:">
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary">{{ dialogDisplay }}</p>
        <span class="text-body-2">Are you sure?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="error" text @click="removeDialogItem()"> Remove </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import * as uuid from "../utility/guid";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListCard from "../components/ListCard.vue";
import { HasValue } from "../utility/general";

// @ is an alias to /src
export default {
  name: "Education",
  components: {
    "list-card": ListCard,
  },
  data: () => ({
    dialog: false,
    dialogKey: "",
    dialogDisplay: "",
  }),
  mounted() {
    if (this.fullList?.length > 0) {
      this.key = this.fullList[0].key;
    }
  },
  methods: {
    save() {
      this.saveEducation();
    },
    navWithKey(path, key) {
      this.key = key;
      this.nav(path + key);
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    async navNewRecord() {
      this.key = uuid.gen();
      this.createEntry({ key: this.key });
      this.$router.push("/educationEntryGeneral/" + this.key);
    },
    launchDialog(item) {
      this.dialogDisplay = item.general;
      this.dialogKey = item.key;
      this.dialog = true;
    },
    removeDialogItem() {
      this.removeEntry({ key: this.dialogKey });
      this.dialog = false;
    },
    HasValue,
    hasDateOrLocation(item) {
      if (item !== undefined) {
        if (item.accolades !== undefined) {
          return item?.date?.length > 0 || item?.location?.length > 0;
        }
      }
      return false;
    },
    buildDateLocatonString(ds, ls) {
      var sb = "";
      if (ds.length > 0) {
        sb += ds;
      }
      if (ls.length > 0) {
        if (sb.length > 0) {
          sb += " - ";
        }
        sb += ls;
      }
      return sb;
    },
    ...mapMutations("education", [
      "createEntry",
      "removeEntry",
      "orderUp",
      "orderDown",
    ]),
    ...mapActions("education", ["saveEducation"]),
  },
  computed: {
    ...mapGetters("education", ["fullList"]),
    ...mapFields("education", { key: "key" }),
    addEduTab() {
      return (this.fullList?.length ?? 0 * 2) + 3;
    }
  },
};
</script>
